let serviceList = [
  {
    title: "Provision of Medications.",
    subtitle: "Why the provision of medications is important? ",
    des: "In situations where government hospital drug supplies are interrupted or terminated due to unforeseen circumstances, the Kidney Protection Society steps in to ensure that patients do not suffer from a lack of essential medications. Understanding the critical nature of timely treatment for kidney disease, we take immediate action to source necessary drugs from external suppliers. When physicians recommend essential medications from outside the hospital, these drugs are often very expensive, and many patients are unable to afford the high costs. Some of these medications are crucial for managing the disease, and without them, treatment may be compromised. In such cases, we provide financial support to ensure that these patients receive the essential medications they need for their treatment and well-being",
    img: "assets/img/icon/s1.svg",
  },
  {
    title: "Assistance for laboratory tests.",
    subtitle: " Why assistance for laboratory tests is necessary? ",
    des: "At times, when the supply of essential reagents for laboratory tests is interrupted or terminated at government hospitals, it creates significant challenges in diagnosing kidney conditions accurately and promptly. Recognizing the importance of early diagnosis in managing kidney disease, the Kidney Protection Society provides financial assistance to ensure that patients can access the necessary laboratory tests from external facilities",
    img: "assets/img/icon/s2.svg",
  },
  {
    title: "Provision of Medical Equipment.",
    subtitle: " Why the provision of medical equipment is important? ",
    des: "Under the advice of healthcare professionals, regular monitoring of blood pressure and blood sugar levels is essential for managing kidney disease and preventing its progression. However, due to the severity of the disease and the geographical distance from healthcare facilities, many patients are unable to visit hospitals frequently for these critical checks. In response to this challenge, the Kidney Protection Society is committed to supporting these individuals by providing essential tools such as glucometers and blood pressure apparatus.",
    short: "(Glucometers, BP Apparatus etc..)",
    img: "assets/img/icon/s3.svg",
  },
  {
    title: "Assistance for accommodation.",
    subtitle: " Why assistance for accommodation is important? ",
    des: "Following a kidney transplantation, patients are required to stay close to the hospital due to the high risks associated with the procedure and the need for frequent monitoring and supervision by healthcare professionals. Unfortunately, many government hospitals do not have the necessary accommodations for these patients, creating a significant challenge for those in need of post-transplant care. To address this, the Kidney Protection Society has stepped in to support these individuals by providing financial assistance for accommodation. This ensures that poor and vulnerable kidney transplant patients can stay near the hospital, receive the necessary medical supervision, and improve their chances of a successful recovery without the added burden of accommodation costs.",
    img: "assets/img/icon/s4.svg",
  },
  {
    title: "Support for Transport facilities.",
    subtitle: " Why support for transport facilities is important ? ",
    des: " Many kidney patients reside in rural and remote areas, making access to hospitals for necessary treatments a significant challenge. Due to financial constraints, many are unable to afford the cost of transportation, which may prevent them from attending crucial medical appointments. Recognizing this barrier, the Kidney Protection Society has taken the initiative to provide financial support for transportation. By offering transport fees, we ensure that these patients can access the necessary healthcare services on time, enabling them to receive regular treatments, follow-up care, and improve their chances of managing their condition effectively. ",
    img: "assets/img/icon/s5.svg",
  },

  {
    title: "Awareness programs and counselling.",
    subtitle:
      " Why support for awareness programs and counselling is important? ",
    des: "Our vision is to free individuals from the devastating impact of kidney disease. To achieve this, we are committed to educating the public on preventive measures, raising awareness about kidney health, and promoting early detection. Through our awareness programs, we aim to empower people with the knowledge needed to reduce the risk of kidney disease. Additionally, for those already affected, we  launch initiatives to guide them on how to manage their condition effectively and lead a normal, fulfilling life despite the challenges of kidney disease.",
    img: "assets/img/icon/s6.svg",
  },
  {
    title: "Support for hygienic living conditions.",
    subtitle: " Why support for hygienic living conditions is important? ",
    des: " As kidney disease has become widespread, particularly among people living in rural areas who are also struggling with poverty, many patients face significant challenges in maintaining proper hygiene, especially after undergoing a kidney transplant. A hygienic environment is crucial for post-transplant recovery, and one of the essential requirements is a separate toilet to ensure cleanliness and minimize the risk of infections. Unfortunately, the financial constraints of these patients make it difficult for them to afford such necessary facilities. In response, the Kidney Protection Society is providing financial assistance to build separate toilets for these patients, ensuring they can maintain proper hygiene and support their recovery in a safe and healthy environment. ",
    img: "assets/img/icon/s6.svg",
  },
];

export default serviceList;
