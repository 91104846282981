import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const CounterAreaFour = () => {
  return (
    <>
      {/*================= counter area start =================*/}
      <div className="counter-area mg-top--100">
        <div className="container pd-bottom-50">
          {/*================= Image Slide Area =================*/}
          <div className="row">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="img-right"
                  src="assets/img/home/1.jpeg"
                  alt="img"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="img-right"
                  src="assets/img/home/2.jpeg"
                  alt="img"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="img-right"
                  src="assets/img/home/3.jpeg"
                  alt="img"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="img-right"
                  src="assets/img/home/4.jpeg"
                  alt="img"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="container">
          <div
            className="section-2 border-radius-20"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
          >
            <div className="row d-flex justify-content-around">
              <div className="col-lg-8 col-md-12">
                <h4>Kandy Kidney Protection Society Established in 1997</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*================= counter area end =================*/}
    </>
  );
};

export default CounterAreaFour;
