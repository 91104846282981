import React from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaFax } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

const AboutAreaFour = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area pd-top-90 pd-bottom-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img
                  className="animate-main-img"
                  src="assets/img/about/14.png"
                  alt="img"
                />
                <img
                  className="main-img m-md-4"
                  src="assets/img/about/about_bnr.png"
                  alt="img"
                />

                {/* 
                <Link
                  className="mt-3 btn btn-border-base"
                  data-aos="fade-right"
                  data-aos-delay="400"
                  data-aos-duration="1500"
                  to="/donation"
                  onClick={scrollToTop}
                >
                  Make a Donation <FaHeart />
                </Link>
                 */}
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title"> Kandy Kidney Protection Society</h6>
                <h2 className="title">
                  Why Support <span>Kandy Kidney Protection Society</span> ?
                </h2>
                <p className="content mb-4 mb-xl-5">
                  The Life’s and Times of Kidney Disease Patients in Sri Lanka:
                  Kidney disease is a life-threatening condition that can affect
                  anyone, not just those living in poverty, but even families
                  who once led normal, stable lives. The impact of this deadly
                  illness is far-reaching, disrupting daily routines, and
                  throwing entire households into financial and emotional
                  turmoil. Even though many families were previously living a
                  normal lifestyle, the sudden onset of kidney disease forces
                  them into a battle for survival—financially, physically, and
                  emotionally.
                </p>
                <p>
                  <b>The Struggles of Rural and Low-Income Families</b>
                  <br />
                  Many kidney disease patients in Sri Lanka come from rural
                  communities where poverty and limited access to healthcare are
                  major barriers to treatment. While these patients desperately
                  need life-saving medical attention, the cost of travel to
                  National Hospitals which are located in main cities is often
                  beyond their means. For families already struggling to make
                  ends meet, paying for transport whether its bus fare or fuel
                  is an impossible task. As a result, these patients may miss
                  critical treatments or even lose the chance to seek medical
                  care altogether.
                </p>

                {/*
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/31.svg' alt='img' />
                  </div>
                  <div className='media-body'>

                  <Link
                      className='mt-3 btn btn-border-base'
                      data-aos='fade-right'
                      data-aos-delay='400'
                      data-aos-duration='1500'
                      to='/about'
                    >
                      Make a Donation  <FaHeart />
                    </Link>

                    <div className='single-progressbar'>
                      
                    

                    </div>
                  </div>
                </div>
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/32.svg' alt='img' />
                  </div>
                  <div className='media-body'>
                    <div className='single-progressbar'>
                      <h6>Cloud Solutions</h6>
                      <div className='skill'>
                        <div
                          style={{ width: "80%" }}
                          className='skill-bar skill1 wow slideInLeft animated'
                        ></div>
                        <p className='skill-count1'>
                          <span className='counter'>80</span> %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
*/}
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              <b>How Kidney Disease Disrupts Family Life</b>
              <br />
              Kidney disease doesn’t just affect the individual, it has the
              potential to disrupt entire family life routine. When a parent is
              diagnosed with kidney disease, the family’s daily routine come to
              a halt. Parents are often unable to work or fulfill their usual
              responsibilities, leaving the entire household in a state of
              uncertainty. Similarly, when a child suffers from kidney disease,
              the parents’ lives are equally turned upside down. They must shift
              their focus entirely to the care of their sick child, and this
              often means sacrificing their ability to earn an income or care
              for other children.
            </p>

            <p>
              The impact is felt acutely in the most vulnerable parts of the
              family. Children’s education often suffers when the parents cannot
              afford the necessary school materials or transport. The emotional
              toll is immense as families face the combined pressure of caring
              for a loved one, ensuring survival, and dealing with financial
              hardship.
            </p>

            <p>
              <b>Malnutrition: A Consequence of Poverty</b>
              <br />
              As if the burden of kidney disease wasn’t enough, many families
              also struggle with malnutrition. Without the ability to provide a
              balanced and nutritious diet, patients and their families are
              often left vulnerable to further health complications. A lack of
              proper nutrition weakens the body’s ability to recover, making it
              even harder for kidney disease patients to withstand the rigors of
              treatment and healing.
            </p>

            <p>
              The inability to afford a proper diet, combined with the emotional
              and physical toll of managing the disease, often leads to
              malnutrition, which accelerates the health problems they are
              already facing. Without support, these families are caught in a
              vicious cycle of illness and deprivation that seems nearly
              impossible to get over.
            </p>

            <p>
              <b>Expensive Medication: A Barrier to Treatment</b>
              <br />
              Another insurmountable challenge for kidney disease patients in
              Sri Lanka is the high cost of medications. Although the Government
              provides very generously all medication for kidney patients free
              of charge and even kidney transplants are done free of charge in
              Government hospitals, which the citizens are thankful and are
              eternally grateful, still there are certain medications that has
              to be purchased from outside. Some treatments and medicines are
              incredibly expensive, and many families simply cannot afford them.
              Without these essential medications, patients are left with little
              hope of recovery, and their condition worsens over time finally
              leading to premature death.
            </p>
            <p>
              For many families, the thought of purchasing medications is a
              daily stress. They must make impossible decisions between buying
              medicine and meeting other basic needs, such as food or
              transportation to the hospital. This dilemma often forces families
              to forgo necessary treatments, leading to deteriorating health and
              a higher risk of death.
            </p>
            <br />
            <p>
              <b>The Challenges of Kidney Transplantation</b>
              <br />
              For some patients, kidney transplantation is the only option to
              save their lives, but this process comes with its own set of
              challenges. Many families cannot afford the costs associated with
              a transplant, including the medications, and post-surgery care.
              This leaves patients with no choice but to continue on dialysis or
              live in constant fear of their condition worsening. The inability
              to afford a transplant means that these families are often left
              with very little hope for the future.
            </p>
            <br />
            <p>
              <b>The Need for a Hygienic Living Environment</b>
              <br />
              After undergoing treatment or a kidney transplant, it is crucial
              for patients to recover in a clean and hygienic environment.
              Unfortunately, many families in Sri Lanka, especially those in
              rural or low-income areas, are unable to provide such basic living
              conditions. The lack of even a separate toilet facility for the
              patient can lead to a high risk of infection and further health
              complications, which can hinder the recovery process.
            </p>
            <br />
            <p>
              <b>Together, We Can Make a Difference</b>
              <br />
              As a Kidney Protection Society, we must pay close attention to the
              living conditions of these patients and their families. . However,
              by coming together as a compassionate community, we can make a
              meaningful difference in their lives.
            </p>
          </div>

          <br />
          <br />
          <br />
          <div
            className="bg-base counter-4-area p-3 border-radius-20"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
          >
            <div className="row">
              {/*
              <div className='col-lg-3 align-self-center col-md-6 '>
                <div className='single-counter-inner'>
                  <h5 className='counter-4-title'>
                    KKPS.LK<br /> 
                  </h5>
                </div>
              </div>
              */}
              <div className="col-lg-3 col-md-6">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <FaPhoneAlt />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Hotline</p>
                    <h5 className="text-white mb-1">+94 81 220 0449</h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 ">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <IoMail />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Email</p>
                    <h5 className="text-white mb-1">info@kkps.lk</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 ">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <FaFax />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Fax</p>
                    <h5 className="text-white mb-1">+94 81 220 0449</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 ">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <IoLocationSharp />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Office</p>
                    <h5 className="text-white mb-1">
                      National Hospital, Kandy.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaFour;
