import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const TeamAreaOne = () => {
  return (
    <>
      {/*====================== team area start ======================*/}
      <div className="team-area bg-relative pd-top-90 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">
              COMMITTEE MEMBERS OF KANDY KIDNEY PROTECTION SOCIETY
            </h6>
            <h3 className="title">
              Our <span>Patrons</span>
            </h3>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Most Venerable Mahanayaka of Asgiriya Chapter of Siyam Maha
                    nikaya Warakagoda Sri Dhammasiddi Ganarathna Bidhana Thero
                    Asgiriya Maha Viharaya, Kandy
                  </h6>
                  <p>Venerable Patron</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Most Venerable Mahanayaka of Malwathu Maha Vihara Chapter of
                    Siyam Maha Nikaya Tibbatuwawe Sri Siddartha Sumangalabidhana
                    Thero Malwathu Maha Viharaya, Kandy
                  </h6>
                  <p>Venerable Patron</p>
                </div>
              </div>
            </div>
          </div>

          {/*
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                  <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='details'>
                  <h5>
                  <Link to='/team-details'>Devon Lane</Link>
                  </h5>
                  <p>Merketing Department</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                  <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='details'>
                  <h5>
                  <Link to='/team-details'>Darrell Steward</Link>
                  </h5>
                  <p>It Department</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/3.png' alt='img' />
                  <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='details'>
                  <h5>
                  <Link to='/team-details'>Bessie Cooper</Link>
                  </h5>
                  <p>Software Department</p>
                </div>
              </div>
            </div>
          </div>
*/}
        </div>

        {/* Executive Committee*/}
        <div className="container pt-5 ">
          {/* 
          <div className="section-title text-center">
            <h3 className="title">
              Our <span>Patrons</span>
            </h3>
          </div>
          */}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Director <br /> National Hospital, Kandy
                  </h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Dr. A.M.L Beligaswatta <br /> Consultant Urological Surgeon
                  </h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Dr. Tilak Abeysekara <br /> Consultant Physician
                  </h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Dr. Nishantha Nanayakkara <br /> Consultant Nephrologists
                  </h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Dr. E.H.C.K Bandara <br /> Consultant Nephrologists
                  </h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Dr. Charitha Fernando <br /> Consultant Vascular and
                    Transplant Surgeon
                  </h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Dr. Buddhima Jayawickrama <br /> Consultant Vascular and
                    Transplant Surgeon
                  </h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Official Patrons*/}
        <div className="container pt-5 ">
          <div className="section-title text-center">
            <h3 className="title">
              Executive <span>Committee</span>
            </h3>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Most Venerable Anunayaka of Malwathu Maha Vihara Chapter of
                    Siyam Maha Nikaya Niyangoda Wijithasiri Thero Malwathu Maha
                    Viharaya, Kandy
                  </h6>
                  <p>President</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Dr. W. G. A. Dissanayake</h6>
                  <p>Senior Vice President</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. Ananda Weerasooriya</h6>
                  <p>Secretary</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. A.A. Munasinghe</h6>
                  <p>Treasurer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Deputy Head of Piriwena Venerable Anandamoorthi Thero Sri
                    Sangaraja Pirivena, Malwathu Maha Viharaya, Kandy
                  </h6>
                  <p>Vice President</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. Nalin Herath</h6>
                  <p>Vice President / Hospital Coordinator</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. M.A.M. Ifham (JP)</h6>
                  <p>Vice President / Local and International Coordinator</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Ms. Chandra Anula Jordan</h6>
                  <p>Vice President / Welfare</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. A.P.Chandrasekara</h6>
                  <p>Vice President / Cultural Activities</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. Gamika Chathuranga Jayasinghe</h6>
                  <p>Vice President / New Projects</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. S.B.M.U. Priyantha Perera</h6>
                  <p>Assistant Secretary</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Internal Auditor */}
        <div className="container pt-5 ">
          <div className="section-title text-center">
            <h3 className="title">
              Internal <span> Auditor / Auditors</span>
            </h3>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. A.M.W Ellepola</h6>
                  <p>Auditor</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Ernst & Young Chartered Accountants</h6>
                  <p>Auditors</p>
                </div>
              </div>
            </div>
          </div>

          <div className="btnlink d-flex justify-content-center">
            <a
              className="btn btn-black mt-0  border-radius-5"
              href="https://drive.google.com/file/d/1sLmxtxKcEq2RUKuh5c3MqINLv3-ZhfMG/view"
              target="_blink"
            >
              Full Committee Members List
            </a>
          </div>
        </div>
      </div>
      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaOne;
