import React from "react";
import { FaPlus } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const BannerFour = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* ================== BannerFour Start ==================*/}
      <div className="banner-area bg-relative banner-area-3">
        <div className="container custom-container">
          <div className="bg-gray">
            <img
              className="img-right"
              src="assets/img/banner-4/kkps_banner.png"
              alt="img"
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-7 align-self-center">
                  <div className="banner-inner">
                    <h6
                      className="subtitle "
                      data-aos="fade-right"
                      data-aos-delay="100"
                      data-aos-duration="1500"
                    >
                      KKPS.LK
                    </h6>
                    <h2
                      className="title "
                      data-aos="fade-right"
                      data-aos-delay="250"
                      data-aos-duration="1500"
                    >
                      Kandy Kidney <span>Protection Society</span>
                    </h2>
                    <p>
                      is a non profit volunteer organization registered under
                      10/97 in the central province of Sri Lanka, Commenced in
                      1997 to assist the Kidney Patients who are in need.All
                      assistance offered by the KKPS ( Kandy Kidney Protection
                      Society) is carried out by careful and complete analysis
                      depending on the need of each patient through the social
                      and welfare officers in their respective village.
                    </p>

                    {/* 
                    <Link
                      className="mt-3 btn btn-border-base"
                      data-aos="fade-right"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                      to="/about"
                      onClick={scrollToTop}
                    >
                      Discover More <FaPlus />
                    </Link>{" "}
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerFour End ==================*/}
    </>
  );
};

export default BannerFour;
