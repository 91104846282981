import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import serviceList from "../scripts/serviceList";

const ServiceAreaGroup = () => {
  const [selectedService, setSelectedService] = useState(null);

  return (
    <>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-100">
        <img
          className="position-bottom-left top_image_bounce"
          src="assets/img/icon/4.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">KKPS.LK</h6>
            <h2 className="title">
              Our
              <span> Services </span>
            </h2>
            <p>
              Get to know more about our past and current services or future
              plans for the welfare of kidney patients.
            </p>
          </div>
          <div className="row">
            {serviceList.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-service-inner text-center">
                  <div className="thumb">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="details">
                    <h5>{data.title}</h5>

                    {/* Touch More Button */}
                    <button
                      className="btn btn-border-base"
                      onClick={() => setSelectedService(data)}
                    >
                      Read More <FaPlus />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal Popup */}
      {selectedService && (
        <div className="popup-overlay" onClick={() => setSelectedService(null)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="close-btn"
              onClick={() => setSelectedService(null)}
            >
              ×
            </button>
            <h6>{selectedService.subtitle}</h6>
            <p>{selectedService.des}</p>
          </div>
        </div>
      )}

      {/* =================== service area end ===================*/}

      {/* Modal Styles */}
      <style>
        {`
          .popup-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }
          
          .popup-content {
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            max-width: 600px;
            text-align: center;
            position: relative;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
          }

          .close-btn {
            position: absolute;
            top: 10px;
            right: 15px;
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

export default ServiceAreaGroup;
