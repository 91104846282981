import React from "react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 p-5 ">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img
                  className="main-img"
                  src="assets/img/kkps_logo-bgr.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  Kandy Kidney <span>Protection Society</span>
                </h2>
                <p className="content mb-4 mb-xl-5">
                  KKPS is a registered non-profit voluntary organization
                  registered under no. 10/97 in the central province of Sri
                  Lanka commenced in 1997 to assist kidney patients by
                  identifying who are in need of such help by conducting
                  awareness sessions in rural areas. KKPS assists such patients
                  affected by kidney disease who are hospitalized and thereafter
                  in the day today life.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 p-5 ">
              <div className="single-about-inner text-center align-items-center">
                <div className="thumb mb-3 mx-auto">
                  <img src="assets/img/icon/2.png" alt="img" />
                </div>
                <div className="details text-center ">
                  <h5>Our Vision</h5>
                  <p>
                    To minimize the incidences of kidney disease and to prevent
                    suffering of affected patients.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-5 ">
              <div className="single-about-inner text-center align-items-center">
                <div className="thumb mb-3 mx-auto">
                  <img src="assets/img/icon/3.png" alt="img" />
                </div>
                <div className="details text-center ">
                  <h5>Our mission</h5>
                  <p>
                    To provide financial, social, and emotional support to
                    patients with Chronic Kidney Disease.To promote public
                    awareness regarding the causes, prevention, and treatment of
                    Kidney Disease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
