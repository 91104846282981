let donationList = [
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "34393",
    bank: "7010",
    branch: "454",
    swift: "BCEYLKLX",
    act: "Current Account",
    img: "assets/img/banks/Boc.png",
  },
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "7054824",
    bank: "7010",
    branch: "454",
    swift: "BCEYLKLX",
    act: "Savings Account",
    img: "assets/img/banks/Boc.png",
  },
  {
    title: "People's Bank, Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "003-2-001-12-0005757",
    bank: "7135",
    branch: "3",
    swift: "PSBKLKLX",
    act: "Savings Account",
    img: "assets/img/banks/Peoples.png",
  },

  {
    title: "Commercial Bank, Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "8040053135",
    bank: "7056",
    branch: "4",
    swift: "CCEYLKLX",
    act: "Savings Account",
    img: "assets/img/banks/ComBank.png",
  },
];

export default donationList;
